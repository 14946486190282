import { Divider } from '@byteclaw/visage';
import { GetStaticProps } from 'next';
import {
  ArticleCard,
  ArticleCardData,
  ArticleGrid,
  Layout,
  NewestArticles,
  SEO,
} from '../components';
import { serializeMdx } from '../helpers/serializeMdx';
import { graphqlClient, imageLoader } from '../services';
import {
  HomepageArticlesDocument,
  HomepageArticlesQuery,
} from '../queries.graphql';
import { siteMetadata } from '../constants';

interface HomepageProps {
  categories: HomepageArticlesQuery['categories'];
  featuredArticles: ArticleCardData[];
  newestArticles: ArticleCardData[];
}

export default function Homepage({
  categories,
  featuredArticles,
  newestArticles,
}: HomepageProps) {
  return (
    <Layout categories={categories}>
      <SEO
        title={siteMetadata.homepageTitle}
        description={siteMetadata.description}
        path=""
      />
      <NewestArticles articles={featuredArticles} />
      <Divider
        lineProps={{ styles: { borderBottomWidth: 2 } }}
        styles={{ mt: 4 }}
      />
      <ArticleGrid>
        {newestArticles.map((article) => (
          <ArticleCard key={article.id} article={article} />
        ))}
      </ArticleGrid>
    </Layout>
  );
}

export const getStaticProps: GetStaticProps<HomepageProps> =
  async function getStaticProps() {
    const articles = await graphqlClient.request<HomepageArticlesQuery>(
      HomepageArticlesDocument,
    );

    const newestArticles = await Promise.all(
      articles.newestArticles.map(async ({ content, ...article }) => {
        const mdxSource = await serializeMdx(content, { imageLoader });

        return {
          ...article,
          timeToRead: mdxSource.readTimes,
        };
      }),
    );
    const featuredArticles = await Promise.all(
      articles.featuredArticles.map(async ({ content, ...article }) => {
        const mdxSource = await serializeMdx(content, { imageLoader });

        return {
          ...article,
          timeToRead: mdxSource.readTimes,
        };
      }),
    );

    return {
      props: {
        categories: articles.categories,
        newestArticles,
        featuredArticles,
      },
    };
  };
